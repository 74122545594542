<template>
  <Combobox :modelValue="modelValue" @update:modelValue="(value) => emit('update:modelValue', value)" :multiple="multiple" nullable>
    <div class="relative">
      <ComboboxButton
        tabindex="0"
        class="flex w-full items-center rounded-md text-left ring-1 ring-inset ring-input focus-within:ring-primary focus-within:hover:ring-primary focus-visible:outline-none"
      >
        <span class="form-input grow border-none bg-transparent px-4 py-3 text-sm focus:outline-none focus:ring-0" :class="{ 'md:pt-1': multiple && selected }">
          <span v-if="multiple && selected">
            <slot name="label-multiple-selected">
              <span class="md:hidden">{{ selected.length }} selected</span>
              <span class="hidden md:flex md:flex-wrap">
                <span v-for="option in selected" class="group mr-2 mt-2 flex items-center space-x-1.5" @click.stop="unselect(option.value)">
                  <span>{{ option.label }}</span>
                  <Icon icon="times" class="text-gray-300 transition-colors group-hover:text-red-500" />
                </span>
              </span>
            </slot>
          </span>
          <span v-else-if="selected">
            <slot name="label-selected">{{ selected.label }}</slot>
          </span>
          <span v-else>
            <slot name="label">{{ label || multiple ? "Select one or more" : "Select one" }}</slot>
          </span>
        </span>
        <Icon icon="chevron-down" class="pointer-events-none px-4 text-xs text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <TransitionRoot leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" @after-leave="query = ''">
        <ComboboxOptions
          :class="
            $cn([
              { 'right-0': position === 'right' },
              'absolute z-10 mt-1 w-full min-w-[15rem] rounded-md bg-white text-sm shadow-lg ring-1 ring-inset ring-input focus:outline-none',
              ui?.options,
            ])
          "
        >
          <div v-if="isSearchable" class="flex items-center">
            <Icon icon="search" class="pl-4 pr-3 text-supplement" />
            <ComboboxInput
              :display-value="(item) => ''"
              placeholder="Search"
              class="form-input grow border-none bg-transparent px-4 py-3 pl-0 text-sm focus:outline-none focus:ring-0"
              @change="query = $event.target.value"
            />
          </div>

          <div class="max-h-60 overflow-auto p-2" :class="{ 'border-t': isSearchable }">
            <slot name="options">
              <div v-if="computedOptions.length === 0 && query !== ''" class="relative cursor-default select-none px-4 py-2 text-gray-700">No results</div>

              <ComboboxOption v-for="option in computedOptions" as="template" :key="option.label" :value="option.value" v-slot="{ selected, active }">
                <div
                  class="group relative flex cursor-pointer select-none items-center space-x-3 rounded px-3 py-2 pl-2 transition-colors"
                  :class="{ 'bg-gray-50': active }"
                >
                  <slot name="item" :item="option" :active="active" :selected="selected">
                    <slot name="item-leading" :item="option" :active="active" :selected="selected">
                      <span class="grid size-4 shrink-0 place-items-center rounded-full bg-white text-xs ring-1 ring-inset ring-input transition-colors">
                        <span :class="{ 'block size-2 rounded-full bg-primary': selected }" />
                      </span>
                    </slot>
                    <slot name="item-label" :item="option" :active="active" :selected="selected">
                      <span class="block truncate" v-html="highlightText(option.label, query, ['group-hover:bg-gray-200', { 'bg-gray-200': active }])" />
                    </slot>
                    <slot name="item-trailing" :item="option" :active="active" :selected="selected" />
                  </slot>
                </div>
              </ComboboxOption>
            </slot>
          </div>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, TransitionRoot } from "@headlessui/vue";
import type { ClassValue } from "clsx";
import type { SelectOption } from "~/types";

const props = withDefaults(
  defineProps<{
    modelValue: string | number | string[] | number[] | null;
    name: string;
    endpoint?: string;
    options?: SelectOption[];
    noResultOptions?: SelectOption[] | [];
    prependOptions?: SelectOption[];
    appendOptions?: SelectOption[];
    label?: string;
    multiple?: boolean;
    searchable?: boolean | null;
    position?: "left" | "center" | "right";
    ui?: Partial<{
      options: ClassValue;
    }>;
  }>(),
  {
    searchable: null,
    position: "left",
  }
);

const emit = defineEmits(["update:modelValue"]);

// Options
let query = ref("");

let baseOptions = ref<SelectOption[]>();

if (props.endpoint) {
  const { data } = await useAsyncData(props.endpoint, () => $larafetch<{ items: SelectOption[] }>(props.endpoint));
  if (data.value) baseOptions.value = data.value.items;
}

const computedOptions = computed(() => {
  let options: SelectOption[] = [];

  // Prepend options
  if (props.prependOptions) {
    props.prependOptions.forEach((option) => options.push(option));
  }

  // Include base options
  if (baseOptions.value) {
    baseOptions.value.forEach((option) => options.push(option));
  } else if (props.options) {
    props.options.forEach((option) => options.push(option));
  }

  // Append options
  if (props.appendOptions) {
    props.appendOptions.forEach((option) => options.push(option));
  }

  // Filter options by search query
  if (query.value) {
    options = options.filter((option) => option.label.toLowerCase().replace(/\s+/g, "").includes(query.value.toLowerCase().replace(/\s+/g, "")));
  }

  // If no filtered results and no result options exist
  if (!options.length && props.noResultOptions?.length) {
    options = props.noResultOptions;
  }

  return options;
});

// Selected option
const selected = computed(() => {
  let value;

  if (props.multiple) {
    value = computedOptions.value.filter((option) => props.modelValue.includes(option.value));
    value = value.length > 0 ? value : null;
  } else {
    value = computedOptions.value.find((option) => option.value === props.modelValue) || null;
  }

  return value;
});

const isSearchable = props.searchable != null ? props.searchable : computedOptions.value.length >= 10;

const unselect = (value) =>
  emit(
    "update:modelValue",
    props.modelValue.filter((option) => option !== value)
  );
</script>
